import { fromJS } from 'immutable';
import * as invoiceActions from '../actions/invoice.actions';
import * as userActions from '../actions/user.actions';
const initialState = fromJS({ list: [], reservingInvoices: {} });

const allInvoices = (state = initialState, action) => {
  switch (action.type) {
    case invoiceActions.INVOICE_LIST_ALL_REQ:
      return state.set('isLoading', fromJS(true));
    case invoiceActions.INVOICE_LIST_ALL_SUCCESS:
      console.log('PL', action.payload);
      return state.withMutations((st) => {
        st.set('list', fromJS(action.payload));
        st.set('isLoading', fromJS(false));
      });
    case invoiceActions.INVOICE_LIST_ALL_FAILED:
      return state.withMutations((st) => {
        st.set('allInvoicesError', fromJS(action.payload));
        st.set('isLoading', fromJS(false));
      });
  }

  switch (action.type) {
    // case invoiceActions.INVOICE_SELECTED:
    //     return state.withMutations(st => {
    //         st.set("currentId", action.payload);
    //         st.set("currentInvoice", null);
    //     });
    case userActions.LOGOUT:
      return state.withMutations((st) => {
        st.set('list', fromJS([]));
        st.set('reservingInvoices', fromJS({}));
      });
  }

  switch (action.type) {
    case invoiceActions.INVOICE_DETAILS_REQ:
      return state.set('currentInvoice', null);
    case invoiceActions.INVOICE_DETAILS_SUCCESS:
      return state.set('currentInvoice', fromJS(action.payload));
    case invoiceActions.INVOICE_DETAILS_FAILED:
      return state.set('invoiceDetailsError', fromJS(action.payload));
  }

  switch (action.type) {
    case invoiceActions.INVOICE_FUND_REQ:
      return state.withMutations((st) => {
        st.set('loadingInvoice', action.payload);
        st.set('currentFunding', null);
      });
    case invoiceActions.INVOICE_FUND_SUCCESS:
      return state.withMutations((st) => {
        st.delete('loadingInvoice');
        st.set('currentFunding', fromJS({ success: true }));
      });
    case invoiceActions.INVOICE_FUND_FAILED:
      return state.withMutations((st) => {
        st.delete('loadingInvoice');
        st.set('currentFunding', fromJS({ error: action.payload }));
      });
  }

  switch (action.type) {
    case invoiceActions.INVOICE_ADD_TO_CART:
      return state.setIn(['reservingInvoices', action.payload.id], {
        status: 'inProgress',
        amount: action.payload.amount,
      });
    case invoiceActions.INVOICE_CART_REFRESH:
      const invoiceIds = Object.keys(action.payload).map(
        (x) => action.payload[x].id
      );
      const newReservings = state.get('reservingInvoices').filter((x, i) => {
        return invoiceIds.indexOf(i) !== -1;
      });
      return state.set('reservingInvoices', newReservings);

    case invoiceActions.INVOICE_ADD_TO_CART_SUCCESS:
      if (action.payload.amount === 0) {
        return state.deleteIn(['reservingInvoices', action.payload.id]);
      }
      return state.setIn(['reservingInvoices', action.payload.id], {
        status: 'success',
        amount: action.payload.amount,
      });
  }

  switch (action.type) {
    case invoiceActions.INVOICE_MULTIPLE_FUND_SUCCESS:
      const resultList = action.payload;
      return state.withMutations((st) => {
        resultList.map((x) => {
          st.deleteIn(['reservingInvoices', x.invoiceId]);
        });
      });
    case invoiceActions.SHOW_NOTIFICATION_SUCCESS:
      const isFullFunding = action.payload.fullFunding;
      return state.withMutations((st) => {
        st.set('showNotification', true);
        st.set('showNotificationType', isFullFunding ? 'full' : 'success');
      });
    case invoiceActions.SHOW_NOTIFICATION_ERROR:
      return state.withMutations((st) => {
        st.set('showNotification', true);
        st.set('showNotificationType', 'error');
      });
    case invoiceActions.REMOVE_NOTIFICATION:
      return state.withMutations((st) => {
        st.set('showNotification', false);
        st.set('showNotificationType', '');
      });
  }

  return state;
};

export default allInvoices;
