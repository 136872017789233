import React from 'react';
import logError from '../../services/logEntries.service';

class LogEntriesErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Log unhandled exceptions

    let { user } = this.props;
    if (!user) {
      user = { anonymous: true };
    }
    logError(
      {
        error: error.message,
        stack: error.stack,
        message: info.componentStack,
        user: user,
      },
      'UI'
    );
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // this.refreshCacheAndReload();
      // You can render any custom fallback UI
      return (
        <div>
          <h1 style={{ textAlign: 'center' }}>Something went wrong.</h1>
          <h1 style={{ textAlign: 'center' }}>
            Don't worry - this error does not affect your account.
          </h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default LogEntriesErrorBoundary;
