import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import localeMiddleware from '../middlewares/localeMiddleware';
import analyticsMiddleware from '../middlewares/analyticsMiddleware';

export const history = createBrowserHistory();
const middleware = routerMiddleware(history);

history.listen((location) => {
  if (window.ga) {
    window.ga('send', 'pageview', location.pathname);
  }
});

export function configureStore(initialState) {
  const dev = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;
  return createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV !== 'production'
      ? compose(
          applyMiddleware(
            thunk,
            middleware,
            localeMiddleware,
            analyticsMiddleware
          ),
          dev
        )
      : compose(
          applyMiddleware(
            thunk,
            middleware,
            localeMiddleware,
            analyticsMiddleware
          )
        )
  );
}
