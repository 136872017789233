import { history } from '../../redux/store/configureStore';
import { navigationURLs } from '../../appPaths';
import { store } from '../../index';
import {
  getUserFromToken,
  LOGIN_FAILED,
  LOGIN_REQ,
  LOGIN_SUCCESS,
} from '../../redux/actions/user.actions';
import { v4 as uuidv4 } from 'uuid';

class Auth0Helper {
  login(credentials) {
    store.dispatch({ type: LOGIN_REQ });
    window.webAuth.login(
      {
        realm: 'Username-Password-Authentication',
        username: credentials.userName,
        password: credentials.password,
      },
      function (err, res) {
        console.log('login auth0 callback called');
        console.log(err);
        console.log(res);
        if (err) {
          store.dispatch({
            type: LOGIN_FAILED,
            payload: 'UNAUTHORIZED_USER',
            isError: true,
          });
        }
      }
    );
  }

  handleAuthentication(props) {
    window.webAuth.parseHash(
      { hash: window.location.hash },
      (err, authResult) => {
        if (err) {
          return console.log(err);
        }

        const id =
          authResult.idTokenPayload['https://creditplace.co.il/investorId'] ||
          '';
        if (id.length > 0) {
          store.dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              token: authResult.accessToken,
              id: id,
              currentFinancialEntity: {
                isSeedInvestor: false,
                isRegularInvestor: true,
              },
            },
          });
          this.setSession(authResult);
          store.dispatch(getUserFromToken());
          history.replace(navigationURLs.investorList);

          // window.webAuth.client.userInfo(authResult.accessToken, (error, user) => {
          //     // Now you have the user's information
          //     console.log(user);
          // });
        } else {
          console.error('incorrect investor id from Auth 0');
          history.replace(navigationURLs.login);
          store.dispatch({
            type: LOGIN_FAILED,
            payload: 'UNAUTHORIZED_USER',
            isError: true,
          });
        }
        return null;
      }
    );
  }

  setSession(authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      );
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);
      localStorage.setItem(
        'meta_id',
        authResult.idTokenPayload['https://creditplace.co.il/investorId'] || ''
      );
      localStorage.setItem('sessionId', uuidv4());
    }
  }

  logout() {
    // Clear access token and ID token from local storage

    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('meta_id');
    localStorage.removeItem('user');
    localStorage.removeItem('sessionId');

    window.webAuth.logout({
      returnTo: window.location.origin + navigationURLs.login,
      federated: true,
    });

    // navigate to the home route
    // history.replace(navigationURLs.login);
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}

export default new Auth0Helper();
