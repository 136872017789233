export const navigationURLs = {
  login: '/',
  register: '/register',
  emailConfirmation: '/register/confirmation',
  createNewPassword: '/register/new-password/:token',
  forgotPassword: '/register/forgotPassword',

  investorList: '/list',
  investorListKyc: '/verify',
  investorListKycForm: '/kyc',
  investorListFaq: '/info',
  investorSettings: '/settings',
  investorContactUs: '/contact',
  invoice: '/invoice/:id',
  investorDashboard: '/dashboard',
  inventorNotification: '/inventor/notification',

  creditPlaceHome: 'http://www.creditplace.com',
};
