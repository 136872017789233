import { navigationURLs } from '../../config/config';
import logError from '../logEntries.service';

const authInterceptor = function (config) {
  const token = sessionStorage.getItem('token');
  const sessionId = localStorage.getItem('sessionId');
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
    config.headers['Client-Session-Id'] = sessionId;
  } else {
    config.headers.Authorization = '';
    delete config.headers.Authorization;
    delete config.headers['Client-Session-Id'];
  }
  return config;
};

const responseInterceptor = null;
const responseErrorInterceptor = (err) => {
  logError(err, 'Server Request');
  try {
    if (
      err.response.data.error === 'INVALID_TOKEN' ||
      err.response.status === 401
    ) {
      sessionStorage.removeItem('token');
      localStorage.removeItem('sessionId');
    }
    if (
      err.response.status === 401 &&
      window.location.pathname !== navigationURLs.login &&
      window.location.pathname !== navigationURLs.register &&
      window.location.pathname !== navigationURLs.emailConfirmation &&
      window.location.pathname !== navigationURLs.createNewPassword &&
      window.location.pathname !== navigationURLs.forgotPassword
    ) {
      window.location = navigationURLs.login;
    }
  } catch (e) {
    return new Promise((resolve, reject) => reject(err));
  }
  return new Promise((resolve, reject) => reject(err));
};

export default {
  authInterceptor,
  responseInterceptor,
  responseErrorInterceptor,
};
