// ConnectedIntlProvider.js

import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

// Our translated strings
import enLocales from '../../lang/locales/en.json';
import frLocales from '../../lang/locales/fr.json';
import nlLocales from '../../lang/locales/nl.json';
import heLocales from '../../lang/locales/he.json';

const localeData = [];
localeData['en'] = enLocales;
localeData['fr'] = frLocales;
localeData['nl'] = nlLocales;
localeData['he'] = heLocales;

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code, fallback to 'en'
const localizedMessages =
  localeData[languageWithoutRegionCode] ||
  localeData[language] ||
  localeData.en;

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
  const locale = // state.auth && state.auth.get("user") && state.auth.getIn(["user", 'lang']) ||
    localStorage.getItem('locale') || state.auth.get('locale') || language;
  const messages = localeData[locale] || localizedMessages;
  return { locale, messages };
}

export default connect(mapStateToProps)(IntlProvider);
