import React, { Fragment } from 'react';
import { logOut } from '../../redux/actions/user.actions';
import { connect } from 'react-redux';

class PrComponent extends React.Component {
  render() {
    const pr = localStorage.getItem('pr');
    return (
      <Fragment>
        {pr != null && (
          <span
            style={{
              position: 'absolute',
              bottom: 50,
              right: 0,
              background: '#ffffff',
              textAlign: 'right',
              margin: '5px',
              padding: '5px',
              border: '1px solid #ededed',
            }}
          >
            working on pr:{pr}
            <a
              onClick={(e) => {
                localStorage.removeItem('pr');
                console.log('pr cleared');
                logOut();
              }}
            >
              {' '}
              clear{' '}
            </a>
          </span>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrComponent);
