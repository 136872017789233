import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import { FormattedMessage } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut } from '../../redux/actions/user.actions';
import { navigationURLs } from '../../config/config.js';

class HeaderDropdown extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      userActive: false,
      logOut: this.props.logOut,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logOut) {
      this.setState({ logOut: nextProps.logOut });
    }
  }

  logOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.state.logOut();
  }

  initPaths(paths) {
    this.paths = paths;
  }

  activate(match, location) {
    if (match && !this.active) {
      this.active = true;
      this.setState({ userActive: true });
    }
    if (!match && this.active) {
      if (this.paths.indexOf(location.pathname) < 0) {
        this.active = false;
        this.setState({ userActive: false });
      }
    }

    return match;
  }

  render() {
    const { investorSettings, investorContactUs } = navigationURLs;
    this.initPaths([investorSettings, investorContactUs]);
    const activeUserClass = this.state.userActive ? 'active' : '';

    return (
      <div className={`navbar-drop-entry ${activeUserClass}`}>
        <div className='dropdown'>
          <div
            href='#'
            className='dropdown-toggle'
            data-toggle='dropdown'
            role='button'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <div className='navbar-userName hidden-xs'>
              {this.props.user &&
                ((this.props.user.firstName && this.props.user.firstName) ||
                  this.props.user.lastName)}
            </div>
            <span className='hidden-xs custom-caret'></span>
          </div>
          <div className='dropdown-menu dropdown-menu-left'>
            <li>
              <NavLink
                to={investorSettings}
                isActive={this.activate}
                activeClassName='active'
                className='header-icon header-icon-settings'
              >
                <span className='hidden-xs'>
                  <FormattedMessage
                    id={'header.tab.dropdownUser.settings'}
                    defaultMessage={'Settings'}
                  />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={investorContactUs}
                isActive={this.activate}
                activeClassName='active'
                className='header-icon header-icon-contactUs'
              >
                <span className='hidden-xs'>
                  <FormattedMessage
                    id={'header.tab.dropdownUser.contactUs'}
                    defaultMessage={'Contact us'}
                  />
                </span>
              </NavLink>
            </li>
            <li>
              <a
                href='/'
                onClick={this.logOut}
                className='header-icon header-icon-logout'
              >
                <span className='hidden-xs'>
                  <FormattedMessage
                    id={'header.tab.dropdownUser.logOut'}
                    defaultMessage={'Logout'}
                  />
                </span>
              </a>
            </li>
          </div>
        </div>
      </div>
    );
  }
}

HeaderDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(logOut());
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(HeaderDropdown));
