import React from 'react';
import autoBind from 'react-autobind';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class RequireUser extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    if (!this.props.user.id) {
      return (
        <Redirect
          to={{
            pathname: '/',
            search: `?redirect=${this.props.location.pathname}${this.props.location.search}`,
          }}
        />
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.get('user') ? state.auth.get('user').toJS() : {},
  };
};

export default withRouter(connect(mapStateToProps)(RequireUser));
