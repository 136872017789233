import { connect } from 'react-redux';
import { logOut } from '../../redux/actions/user.actions';
import { withRouter } from 'react-router-dom';
import Header from './Header';

const mapStateToProps = (state) => {
  return {
    user: state.auth.get('user') ? state.auth.get('user').toJS() : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(logOut());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
