import { fromJS } from 'immutable';
import * as commonActions from '../actions/common.actions';
const initialState = fromJS({ enumerations: [], enumerationsLoading: true });

const app = (state = initialState, action) => {
  switch (action.type) {
    case commonActions.GET_ENUMERATIONS_SUCCESS:
      state = state.set('enumerationsLoading', false);
      return state.set('enumerations', action.payload);
    case commonActions.GET_ENUMERATIONS_REQUEST:
      return state.set('enumerationsLoading', true);
    case commonActions.GET_ENUMERATIONS_FAILED:
      state.set('enumerationsLoading', false);
      return state.set('enumerations', {});
  }

  return state;
};

export default app;
