import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { termsAndConditionsLink } from '../../config/config';
import { defineMessages, injectIntl } from 'react-intl';
import CookieConsent from 'cookieconsent';
import { userAcceptConsent } from '../../redux/actions/user.actions';
import { appConfig } from '../../config/config';

const messages = defineMessages({
  contentText: {
    id: 'cookieConsent.content.text',
    defaultMessage:
      'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, provide you with the best experience possible.',
  },
  linkText: {
    id: 'cookieConsent.link.text',
    defaultMessage: 'Cookie Policy',
  },
  buttonText: {
    id: 'cookieConsent.button.text',
    defaultMessage: 'OK',
  },
});

class AppConsent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { props } = this;
    const cc = window.cookieconsent;

    const consentPopup = document.getElementsByClassName('cc-window');

    if (!!consentPopup.length)
      consentPopup[0].parentNode.removeChild(consentPopup[0]);

    if (Object.keys(props.user).length !== 0) {
      cc.initialise({
        palette: {
          popup: {
            background: '#F0F0F0',
            text: '#9C9C9C',
          },
          button: {
            background: '#02B1FF',
            text: '#FFF',
          },
        },
        position: 'bottom-left',
        content: {
          message: this.props.intl.formatMessage({
            id: messages.contentText.id,
            defaultMessage: messages.contentText.defaultMessage,
          }),
          dismiss: this.props.intl.formatMessage({
            id: messages.buttonText.id,
            defaultMessage: messages.buttonText.defaultMessage,
          }),
          link: this.props.intl.formatMessage({
            id: messages.linkText.id,
            defaultMessage: messages.linkText.defaultMessage,
          }),
          href: termsAndConditionsLink[this.props.locale],
        },
        onStatusChange: function (status) {
          props.acceptConsent();
        },
        onInitialise: function (status) {
          if (
            !!props.user.investorId &&
            props.user.cookiePolicyConsent !== appConfig.cookiePolicyVersion
          ) {
            this.revokeChoice();
            this.open();
          }
        },
      });
    }

    return <Fragment>{this.props.children}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.get('user') ? state.auth.get('user').toJS() : {},
    locale: state.auth.get('locale'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptConsent: () => dispatch(userAcceptConsent()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AppConsent));
