import analytics from '../../services/analytics.service';
import {
  USER_FROM_TOKEN_SUCCESS,
  LOGIN_SUCCESS,
} from '../actions/user.actions';
import { KYC_UPLOAD_PROGRESS } from '../actions/kyc.actions';

const removeToken = (payload = {}) => {
  const _token = payload.token;
  if (!_token) {
    return payload;
  }
  return Object.assign({}, payload, { token: `${_token.slice(0, 5)}...` });
};

const analyticsMiddleware = (_analytics) => {
  return (state) => {
    const _state = state.getState();
    return (next) => (action) => {
      const _payload = action.payload
        ? removeToken(action.payload)
        : action.payload;
      if (action.type === KYC_UPLOAD_PROGRESS) {
        return next(action);
      }
      if (
        action.type === USER_FROM_TOKEN_SUCCESS ||
        action.type === LOGIN_SUCCESS
      ) {
        //_analytics.identifyUser(_payload);
      }
      if (action.type === '@@router/LOCATION_CHANGE') {
        // _analytics.track(`Path changed to ${action.payload.pathname}`, {
        //   "payload": action.payload,
        //   "state": _state
        // });
        return next(action);
      }
      // _analytics.track(action.type, {
      //     "payload": _payload,
      //     "state": _state
      // });
      return next(action);
    };
  };
};

export default analyticsMiddleware(analytics);
