import {
  // getDashboardData,
  getDashboardTransactions,
  getDashboardAssets,
  getDashboardTotals,
} from '../../services/investorDashboard.service';
import { withdraw, getTaxInvoiceDocument } from '../../services/user.service';
/*
export const GET_INVESTOR_DASHBOARD_DATA_STARTED = "GET_INVESTOR_DASHBOARD_DATA_STARTED";
export const GET_INVESTOR_DASHBOARD_DATA_SUCCESS = "GET_INVESTOR_DASHBOARD_DATA_SUCCESS";
export const GET_INVESTOR_DASHBOARD_DATA_FAILED = "GET_INVESTOR_DASHBOARD_DATA_FAILED";
*/
export const CASH_OUT = 'CASH_OUT';
export const CASH_OUT_SUCCESS = 'CASH_OUT_SUCCESS';
export const CASH_OUT_FAILURE = 'CASH_OUT_FAILURE';

export const TRANSACTIONS_DATA_LOADING = 'TRANSACTIONS_DATA_LOADING';
export const TRANSACTIONS_DATA_SUCCESS = 'TRANSACTIONS_DATA_SUCCESS';
export const TRANSACTIONS_DATA_FAILURE = 'TRANSACTIONS_DATA_FAILURE';

export const ASSETS_DATA_LOADING = 'ASSETS_DATA_LOADING';
export const ASSETS_DATA_SUCCESS = 'ASSETS_DATA_SUCCESS';
export const ASSETS_DATA_FAILURE = 'ASSETS_DATA_FAILURE';

export const TOTALS_DATA_LOADING = 'TOTALS_DATA_LOADING';
export const TOTALS_DATA_SUCCESS = 'TOTALS_DATA_SUCCESS';
export const TOTALS_DATA_FAILURE = 'TOTALS_DATA_FAILURE';

/*
export const getInvestorDashboardData = () => (dispatch, getState) => {
    const locale = getState().auth.getIn(['locale']);
    dispatch({ type: GET_INVESTOR_DASHBOARD_DATA_STARTED, payload: {} });
    return getDashboardData(locale)
        .then(response => {
            dispatch({ type: GET_INVESTOR_DASHBOARD_DATA_SUCCESS, data: response });
        })
        .catch(err => {
            dispatch({
                type: GET_INVESTOR_DASHBOARD_DATA_FAILED,
                payload: err,
                isError: true
            });
        });
};
*/
export const customerWithdraw = (amt, crmCustId, custidType) => (dispatch) => {
  dispatch({ type: CASH_OUT });
  // const data = { amt, crmCustId, custidType };
  // todo adapt CEM backend
  const data = { amount: amt };
  return new Promise((resolve, reject) => {
    return withdraw(data)
      .then((response) => {
        dispatch({ type: CASH_OUT_SUCCESS });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: CASH_OUT_FAILURE,
          payload: err,
          isError: true,
        });
        reject();
      });
  });
};

export const getTransactionsData = () => (dispatch, getState) => {
  const locale = getState().auth.getIn(['locale']);
  dispatch({ type: TRANSACTIONS_DATA_LOADING, payload: {} });
  return getDashboardTransactions(locale)
    .then((response) => {
      dispatch({
        type: TRANSACTIONS_DATA_SUCCESS,
        payload: { data: response.transactions },
      });
    })
    .catch((err) => {
      dispatch({
        type: TRANSACTIONS_DATA_FAILURE,
        payload: err,
      });
    });
};
export const getTaxInvoiceDoc = (doc) => (dispatch) => {
  return getTaxInvoiceDocument(doc)
    .then((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', doc);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      // TODO add here logic for file download error
    });
};

export const getAssetsData = () => (dispatch, getState) => {
  const locale = getState().auth.getIn(['locale']);
  dispatch({ type: ASSETS_DATA_LOADING, payload: {} });
  return getDashboardAssets(locale)
    .then((response) => {
      dispatch({
        type: ASSETS_DATA_SUCCESS,
        payload: { data: response.assets },
      });
    })
    .catch((err) => {
      dispatch({
        type: ASSETS_DATA_FAILURE,
        payload: err,
      });
    });
};

export const getTotalsData = () => (dispatch, getState) => {
  const locale = getState().auth.getIn(['locale']);
  dispatch({ type: TOTALS_DATA_LOADING, payload: {} });
  return getDashboardTotals(locale)
    .then((response) => {
      dispatch({
        type: TOTALS_DATA_SUCCESS,
        payload: { data: response.investorData },
      });
    })
    .catch((err) => {
      dispatch({
        type: TOTALS_DATA_FAILURE,
        payload: err,
      });
    });
};
