import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';

import HeaderDropdown from './HeaderDropdown';
import InvestorTabs from './InvestorTabs';

class HeaderContent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  renderTabs(tabs) {
    const numberOfTabs = tabs.length;
    const xsTabSize = Math.floor(15 / numberOfTabs);
    const smTabSize = Math.floor(24 / numberOfTabs);
    const mdTabSize = Math.floor(18 / numberOfTabs);
    const lgTabSize = Math.floor(18 / numberOfTabs);
    return tabs.map((tab, index) => (
      <div
        key={index}
        className={`col-xs-${xsTabSize} col-sm-${smTabSize} col-md-${mdTabSize} col-lg-${lgTabSize} nav-tab nav-tab-buttonWidth maxWidthTab`}
      >
        <NavLink
          disabled={tab.isDisable}
          to={tab.linkTo}
          className={`navbar-entry navbar-entry-${tab.className}`}
          activeClassName='active'
        >
          <span className='hidden-xs'>{tab.displayLabel}</span>
        </NavLink>
      </div>
    ));
  }

  tabs() {
    return <InvestorTabs user={this.props.user} renderTabs={this.renderTabs} />;
  }

  render() {
    return (
      <div className='header-content'>
        <div className='col-xs-15 col-sm-17 col-md-17 col-lg-17 nav-tab-container no-gutters'>
          {!!this.props.user && this.tabs()}
        </div>
        <div className='col-xs-3 col-sm-5 col-md-3 nav-tab nav-tab-userActionMenu nav-tab-buttonWidth'>
          <HeaderDropdown user={this.props.user} />
        </div>
      </div>
    );
  }
}

HeaderContent.propTypes = {
  user: PropTypes.object.isRequired,
};

export default HeaderContent;
