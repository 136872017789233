import './Header.scss';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';

import HeaderContent from './HeaderContent';
import MobileMenu from './MobileMenu';
import blueLogo from './logo_blue.png';
import MobileTitle from './MobileTitle';

class Header extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      user: this.props.user,
      mobileMenuOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  openMobileMenu() {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  }

  render() {
    if (!this.state.user) {
      return '';
    }

    return (
      <nav className='navbar navbar-inverse navbar-investor'>
        <div className='container container-fluid'>
          <MobileTitle onClose={this.openMobileMenu} />
          <div className='col-xs-4 col-sm-2 no-gutters nav-tab nav-tab-buttonWidth-logo'>
            <Link className='navbar-brand logo' to='/'>
              <img
                className='header-logo'
                src={blueLogo}
                alt='CreditPlace Logo'
              />
            </Link>
          </div>
          <HeaderContent user={this.state.user} />
        </div>
        <MobileMenu
          open={this.state.mobileMenuOpen}
          onClose={this.openMobileMenu}
        />
      </nav>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Header;
