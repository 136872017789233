import { fromJS } from 'immutable';
import * as registrationAction from '../actions/registration.actions';
const initialState = fromJS({ list: [] });

const registration = (state = initialState, action) => {
  switch (action.type) {
    case registrationAction.CAPTCHA_DISABLED:
      const payload = action.payload;
      return state.withMutations((st) =>
        st.set('captchaDisabled', payload.disabled)
      );

    case registrationAction.CREATE_NEW_CUSTOMER_SUBMITTED:
      return state.set(fromJS(action.payload));
    case registrationAction.CREATE_NEW_CUSTOMER_SUBMITTED_SUCCESS:
      return state.withMutations((st) => {
        st.set('email', action.payload.email);
        st.set('firstName', action.payload.firstName);
        st.set('middleName', action.payload.middleName);
        st.set('lastName', action.payload.lastName);
        st.set('phoneNumber', action.payload.phoneNumber);
        st.set('selectLanguage', action.payload.selectLanguage);
        st.set('password', action.payload.password);
        st.set('status', action.payload.status);
      });
    case registrationAction.CREATE_NEW_CUSTOMER_SUBMITTED_FAILURE:
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
      return state
        .set(fromJS(action.payload))
        .set('status', fromJS(action.payload.status));
    // hook
    case registrationAction.CREATE_PASSWORD_SUCCESS:
    case registrationAction.CREATE_PASSWORD_FAILURE:
      return state.withMutations((st) => {
        st.set('status', fromJS(action.payload.status));
        st.set('password', fromJS(action.payload.password));
        st.set('email', action.payload.email);
      });
    // HOOK
    // case registrationAction.CREATE_PASSWORD_FAILURE:
    //     return state.withMutations(st => {
    //         st.set('status', fromJS(action.payload.status));
    //         st.set('password', '');
    //         // st.set('email', action.payload.email);
    //     });
    case registrationAction.CLEAR_STATUS:
      return state.set('status', action.payload);
    case registrationAction.CLEAR_PASSWORDS:
      return state.withMutations((st) => {
        st.delete('inputPassword');
        st.delete('retypeInputPassword');
        st.delete('currentPassword');
      });
    case registrationAction.INPUT_PASSWORD_CHANGED:
      return state.set('inputPassword', fromJS(action.payload));
    case registrationAction.RETYPE_INPUT_PASSWORD_CHANGED:
      return state.set('retypeInputPassword', fromJS(action.payload));
    case registrationAction.CURRENT_PASSWORD_CHNAGED:
      return state.set('currentPassword', fromJS(action.payload));
    case registrationAction.RESEND_EMAIL:
    case registrationAction.RESEND_EMAIL_SUCCESS:
    case registrationAction.RESEND_EMAIL_FAILURE:
      return state.set('status', fromJS(action.payload.status));
    case registrationAction.SEND_RESET_PASSWORD_SUCCESS:
      const onlyEmail = action.payload.email.split('||')[0];
      return state.withMutations((st) => {
        st.set('email', onlyEmail);
        st.set('status', action.payload.status);
      });
    case registrationAction.SEND_RESET_PASSWORD_FAILURE:
      console.log('send reset failure!!');
      console.log(action.payload);
      return state.withMutations((st) => {
        st.set('email', '');
        st.set('status', action.payload.status);
      });
    case registrationAction.EXECUTE_GOOGLE_VERIFY:
      window.grecaptcha.execute();
      return state;
    case registrationAction.CHANGE_GOOGLE_CAPTCHA_LANGUAGE:
      if (!state.get('captchaDisabled')) {
        window.resetCaptcha = () => {
          window.grecaptcha.reset();
        };
        document.querySelector('.g-recaptcha').innerHTML = '';
        const script = document.createElement('script');
        script.src =
          'https://www.google.com/recaptcha/api.js?onload=resetCaptcha&hl=' +
          action.payload;
        script.async = true;
        script.defer = true;
        document.querySelector('head').appendChild(script);
      }

      return state;
    default:
      return state;
  }
};

export default registration;
