import translator from '../Utils/translationString';
import { defineMessages } from 'react-intl';
import { navigationURLs } from '../../config/config';
import React from 'react';

defineMessages({
  'webapp.cim.title': {
    id: 'webapp.cim.title',
    defaultMessage: 'CIM Web Application',
  },

  'webapp.page.title.login': {
    id: 'webapp.page.title.login',
    defaultMessage: 'Creditplace - Login',
  },
  'webapp.page.title.signup': {
    id: 'webapp.page.title.signup',
    defaultMessage: 'Creditplace - Signup',
  },
  'webapp.page.title.passwordReset': {
    id: 'webapp.page.title.passwordReset',
    defaultMessage: 'Creditplace - Password reset',
  },
  'webapp.page.title.arena': {
    id: 'webapp.page.title.arena',
    defaultMessage: 'Creditplace - Arena',
  },
  'webapp.page.title.portfolio': {
    id: 'webapp.page.title.portfolio',
    defaultMessage: 'Creditplace - Portfolio',
  },
  'webapp.page.title.settings': {
    id: 'webapp.page.title.settings',
    defaultMessage: 'Creditplace - Settings',
  },
  'webapp.page.title.contactUs': {
    id: 'webapp.page.title.contactUs',
    defaultMessage: 'Creditplace - Contact us',
  },
});

export const setPageTitle = (messageId) => {
  document.title = translator.translationString({ id: messageId });
};

export const refreshCacheAndReload = () => {
  if (caches) {
    console.log('Clearing cache and hard reloading...');
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location = navigationURLs.login;
  //window.location.reload(true);
};

export const Loading = (props) => {
  if (props.error) {
    return refreshCacheAndReload();
  } else {
    return <div />;
  }
};
