import React from 'react';
// import PropTypes from "prop-types";
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { logOut } from '../../redux/actions/user.actions';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { navigationURLs } from '../../config/config';
import autoBind from 'react-autobind';

class AppIdleTimer extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.idleTimer = null;
  }

  onAction(e) {
    // console.log("user did something", e);
  }

  onActive(e) {
    // console.log("user is active", e);
    // console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  onIdle(e) {
    console.log('user is idle', e);
    if (this.props.user.id) {
      this.props.logOut();
      setTimeout(() => {
        this.props.navigate(navigationURLs.login);
      }, 1000);
    }
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={1000}
          timeout={1000 * 60 * 30}
        />
        {this.props.children}
      </div>
    );
  }
}

AppIdleTimer.propTypes = {
  logOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.get('user') ? state.auth.get('user').toJS() : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(logOut());
    },
    navigate: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppIdleTimer);
