import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Header from '../Header/Header.container';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div>
        <Header />
        <div className='mainPageContainer'>{this.props.children}</div>
      </div>
    );
  }
}

export default DashboardLayout;
