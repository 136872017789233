import { connect } from 'react-redux';
import {
  getUserFromToken,
  changeLocale,
} from '../../redux/actions/user.actions';
import App from './App';

const mapStateToProps = (state) => {
  return {
    doneTokenAuth: state.auth.get('doneTokenAuth'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserFromToken: () =>
      dispatch(getUserFromToken(sessionStorage.getItem('token'))),
    changeLocale: (locale) => dispatch(changeLocale(locale)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
