import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { userReducer } from './user.reducers';
import invoice from './invoice.reducers';
import registration from './registration.reducers';
import kyc from './kyc.reducers';
import investorDashboard from './investorDashboard.reducers';
import errorHandler from './error.reducers';
import app from './common.reducers';

const rootReducer = combineReducers({
  auth: userReducer,
  errorHandler,
  invoice,
  routing,
  kyc,
  investorDashboard,
  registration,
  app,
});

export default rootReducer;
