import { fromJS } from 'immutable';
const initialState = fromJS({ list: [] });

const errorHandler = (state = initialState, action) => {
  if (action.isError) {
    state.set('GenericError', action.payload);
  } else {
    state.set('GenericError', null);
  }
  return state;
};

export default errorHandler;
