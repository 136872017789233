import './Header.scss';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import { navigationURLs } from '../../config/config.js';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import InvestorTabs from './InvestorTabs';

class MobileTitle extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderTabs(tabs) {
    return tabs.map((tab, index) => (
      <NavLink
        disabled={tab.isDisable}
        key={index}
        to={tab.linkTo}
        className={`title`}
        activeClassName='active'
      >
        <span className=''>{tab.displayLabel}</span>
      </NavLink>
    ));
  }

  tabs() {
    return <InvestorTabs user={this.props.user} renderTabs={this.renderTabs} />;
  }

  render() {
    const { investorSettings, investorContactUs } = navigationURLs;
    return (
      <div>
        <div className='header-mobile-nav' onClick={this.props.onClose}></div>
        <div className='mobile-titles'>
          {!!this.props.user && this.tabs()}
          {/*    <NavLink*/}
          {/*        to={investorSettings}*/}
          {/*        isActive={this.activate}*/}
          {/*        activeClassName="active"*/}
          {/*        className="title"*/}
          {/*    >*/}
          {/*<span className="">*/}
          {/*  <FormattedMessage*/}
          {/*      id={"header.tab.dropdownUser.settings"}*/}
          {/*      defaultMessage={"Settings"}/>*/}
          {/*</span>*/}
          {/*    </NavLink>*/}
          <NavLink
            to={investorContactUs}
            isActive={this.activate}
            activeClassName='active'
            className='title'
          >
            <span className=''>
              <FormattedMessage
                id={'header.tab.dropdownUser.contactUs'}
                defaultMessage={'Contact us'}
              />
            </span>
          </NavLink>
        </div>
      </div>
    );
  }
}

MobileTitle.propTypes = {
  // open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MobileTitle;
