import axios from 'axios';
import interceptors from './interceptors';
import { endPoints } from '../config/config';

const newPlatformInstance = axios.create({
  baseURL: endPoints.investor,
  timeout: 10000,
});

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

newPlatformInstance.interceptors.request.use(interceptors.authInterceptor);
newPlatformInstance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

export const getAllEnumerations = () => {
  return newPlatformInstance
    .get('/getAllEnumerations', axiosConfig)
    .then((res) => {
      return res.data;
    });
};
