import { fromJS } from 'immutable';
import * as kycActions from '../actions/kyc.actions';
import * as userActions from '../actions/user.actions';
import { KYC_LINK_FAILED, KYC_LINK_SUCCESS } from '../actions/user.actions';
// const initialState = fromJS({
//     uploadInProgress: {},
//     uploadProgress: {},
//     docs: {}
// });
//
// const allKycDocs = (state = initialState, action) => {
//     switch (action.type) {
//         case userActions.LOGOUT:
//             return state.withMutations(st => {
//                 st.set("uploadInProgress", {});
//                 st.set("uploadProgress", {});
//                 st.set("docs", {});
//             });
//         case kycActions.KYC_UPLOAD_PROGRESS:
//             return state.setIn(["uploadProgress", action.fileType], action.percentCompleted);
//         case kycActions.KYC_DOC_UPLOAD_STARTED:
//             return state.setIn(["uploadInProgress", action.fileType], true);
//         case kycActions.KYC_DOC_UPLOAD_SUCCESS:
//             state = state.setIn(
//                 ["docs", action.fileType],
//                 fromJS(action.response)
//             );
//             return state.setIn(["uploadInProgress", action.fileType], false);
//         case kycActions.KYC_DOC_UPLOAD_FAILED:
//             return state.setIn(["uploadInProgress", action.fileType], false);
//         case kycActions.ALL_KYC_DOCS_SUCCESS:
//             return state.set("docs", fromJS(action.payload));
//         case kycActions.ALL_KYC_DOCS_FAILED:
//             return state.set("allIKycDocsError", fromJS(action.payload));
//         case kycActions.KYC_DOC_DELETE_STARTED:
//             return state;
//         case kycActions.KYC_DOC_DELETE_SUCCESS:
//             return state.setIn(["docs", action.fileType], false);
//         case kycActions.KYC_DOC_DELETE_FAILED:
//             return state.set("deleteDocError", fromJS(action.payload));
//         case kycActions.KYC_SUBMIT_START:
//             return state.set("submitInProgress", true);
//         case kycActions.KYC_SUBMIT_SUCCESS:
//             state = state.set("status", "pending");
//             return state.set("submitInProgress", false);
//         case KYC_LINK_SUCCESS:
//             return state.set("kycLink", fromJS(action.payload.url));
//         case KYC_LINK_FAILED:
//             return state.set("kycLink", "");
//
//
//     }
//
//     return state;
// };
//
// export default allKycDocs;
const initialState = fromJS({
  uploadInProgress: {},
  uploadProgress: {},
  docs: {},
  inProgress: null,
  updateInProgress: null,
  data: {},
});

const allKycDocs = (state = initialState, action) => {
  switch (action.type) {
    case kycActions.KYC_GET_PROGRESS:
    case kycActions.KYC_SUBMIT_PROGRESS:
      return state.set('inProgress', true);
    case kycActions.KYC_GET_FAILED:
    case kycActions.KYC_SUBMIT_FAILED:
      return state.set('inProgress', false);
    case kycActions.KYC_GET_SUCCESS:
      state = state.set('inProgress', false);
      return state.set('data', action.payload);
    case kycActions.KYC_SUBMIT_DONE:
      state = state.set('inProgress', false);
      return state.set('pdfLink', action.payload);
    case kycActions.DOC_GET_PROGRESS:
      return state.set('inProgress', true);
    case kycActions.DOC_GET_FAILED:
      return state.set('inProgress', false);
    case kycActions.DOC_GET_SUCCESS:
      state = state.set('inProgress', false);
      return state.set('docs', action.payload);
    case kycActions.KYC_UPDATE_PROGRESS:
      return state.set('updateInProgress', true);
    case kycActions.KYC_UPDATE_FAILED:
      return state.set('updateInProgress', false);
    case kycActions.KYC_UPDATE_SUCCESS:
      state = state.set('updateInProgress', false);
      return state.set('data', { ...state.data, ...action.payload });
  }

  return state;
};

export default allKycDocs;
