import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

import { navigationURLs } from '../../config/config';

class InvestorTabs extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    renderTabs: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const isSeedInvestor =
      !!this.props.user &&
      this.props.user.currentFinancialEntity.isSeedInvestor;
    let tabs = [
      {
        id: 'dashboard',
        className: 'dashboard',
        linkTo:
          this.props.user.investorStatus === 'Active' ||
          this.props.user.investorStatus === 'Approved'
            ? navigationURLs.investorDashboard
            : '#',
        isDisable:
          this.props.user.investorStatus !== 'Approved' &&
          this.props.user.investorStatus !== 'Active',
        displayLabel: (
          <FormattedMessage
            id={'header.tab.dashboard'}
            defaultMessage={'Portfolio'}
          />
        ),
      },
    ];

    if (!isSeedInvestor) {
      tabs = [
        {
          id: 'arena',
          className: 'arena',
          linkTo:
            this.props.user.investorStatus === 'Active' ||
            this.props.user.investorStatus === 'Approved'
              ? navigationURLs.investorList
              : '#',
          isDisable:
            this.props.user.investorStatus !== 'Approved' &&
            this.props.user.investorStatus !== 'Active',
          displayLabel: (
            <FormattedMessage
              id={'header.tab.arena'}
              defaultMessage={'Investment arena'}
            />
          ),
        },
        ...tabs,
      ];
    }

    return this.props.renderTabs(tabs);
  }
}

export default InvestorTabs;
