import {
  CHANGE_LOCALE,
  USER_FROM_TOKEN_SUCCESS,
} from '../actions/user.actions';
import translator from '../../components/Utils/translationString';

const localeMiddleware = (_translator) => {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (action.type === CHANGE_LOCALE) {
        _translator.setLocale(action.payload);
      }
      if (action.type === USER_FROM_TOKEN_SUCCESS) {
        _translator.setLocale(
          localStorage.getItem('locale') || action.payload.lang
        );
      }
      return next(action);
    };
};

export default localeMiddleware(translator);
