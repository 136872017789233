import axios from 'axios';
import { endPoints } from '../config/config';

const instance = axios.create({
  baseURL: endPoints.collector,
  timeout: 10000,
});

const axiosConfig = {
  headers: {},
};

const logError = (data, logger) => {
  const user = localStorage.getItem('user');
  const userAgent = window.navigator.userAgent;
  const path = window.location.href;
  let userData = { ...data };

  return instance
    .post('', {
      event: { data: userData, user: user, path: path, userAgent: userAgent },
      logger: logger,
      level: 'ERROR',
    })
    .then((res) => {
      //TODO add if needed here logic
    })
    .catch((err) => {
      //TODO add if needed here logic
    });
};

export default logError;
