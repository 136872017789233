/* eslint-disable react/no-multi-comp */
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import DashboardLayout from '../Layouts/DashboardLayout';
import RequireUser from '../RequireUser/RequireUser';
import Auth0Helper from '../Utils/Auth0Helper';
import { Loading } from '../Utils/pageUtils';

const Registration = Loadable({
  loader: () => import('../registration/Registration'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('../login/Login'),
  loading: Loading,
});

const Investor = Loadable({
  loader: () => import('../investor/Investor'),
  loading: Loading,
});

export default (
  <Switch>
    <Route exact path='/' component={Login} />
    <Route
      path='/auth0loginCallback'
      render={(props) => {
        console.log(props);
        Auth0Helper.handleAuthentication(props);
        return <div>loading...</div>;
      }}
    />
    <Route path='/register' component={Registration} />
    <RequireUser>
      <DashboardLayout>
        <Route path='/' component={Investor} />
      </DashboardLayout>
    </RequireUser>
  </Switch>
);
