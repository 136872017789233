import {
  approvedInvoices,
  getInvoiceDetails,
  fundInvoice,
  fundMultipleInvoices,
} from '../../services/invoice.service';

import { getWalletStatus } from './user.actions';

export const INVOICE_LIST_ALL_REQ = 'INVOICE_LIST_ALL_REQ';
export const INVOICE_LIST_ALL_SUCCESS = 'INVOICE_LIST_ALL_SUCCESS';
export const INVOICE_LIST_ALL_FAILED = 'INVOICE_LIST_ALL_FAILED';

export const INVOICE_DETAILS_REQ = 'INVOICE_DETAILS_REQ';
export const INVOICE_DETAILS_SUCCESS = 'INVOICE_DETAILS_SUCCESS';
export const INVOICE_DETAILS_FAILED = 'INVOICE_DETAILS_FAILED';

export const INVOICE_FUND_REQ = 'INVOICE_FUND_REQ';
export const INVOICE_FUND_SUCCESS = 'INVOICE_FUND_SUCCESS';
export const INVOICE_FUND_FAILED = 'INVOICE_FUND_FAILED';

export const INVOICE_MULTIPLE_FUND_REQ = 'INVOICE_MULTIPLE_FUND_REQ';
export const INVOICE_MULTIPLE_FUND_SUCCESS = 'INVOICE_MULTIPLE_FUND_SUCCESS';
export const INVOICE_MULTIPLE_FUND_FAILED = 'INVOICE_MULTIPLE_FUND_FAILED';

export const SHOW_NOTIFICATION_SUCCESS = 'SHOW_NOTIFICATION_SUCCESS';
export const SHOW_NOTIFICATION_ERROR = 'SHOW_NOTIFICATION_ERROR';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const INVOICE_ADD_TO_CART = 'INVOICE_ADD_TO_CART';
export const INVOICE_CART_REFRESH = 'INVOICE_CART_REFRESH';
export const INVOICE_ADD_TO_CART_SUCCESS = 'INVOICE_ADD_TO_CART_SUCCESS';

export const listApprovedInvoices = () => (dispatch, getState) => {
  const locale = getState().auth.getIn(['locale']);

  dispatch({ type: INVOICE_LIST_ALL_REQ });
  return approvedInvoices(locale)
    .then((invoices) => {
      dispatch({ type: INVOICE_LIST_ALL_SUCCESS, payload: invoices.invoices });
      dispatch({ type: INVOICE_CART_REFRESH, payload: invoices.invoices });
    })
    .catch((err) => {
      dispatch({
        type: INVOICE_LIST_ALL_FAILED,
        payload: err,
        isError: true,
      });
    });
};

export const invoiceDetails = (id) => (dispatch) => {
  dispatch({ type: INVOICE_DETAILS_REQ });
  return getInvoiceDetails(id)
    .then((invoice) => {
      dispatch({ type: INVOICE_DETAILS_SUCCESS, payload: invoice });
    })
    .catch((err) => {
      dispatch({
        type: INVOICE_DETAILS_FAILED,
        payload: err,
        isError: true,
      });
    });
};

export const removeNotification = () => (dispatch) => {
  dispatch({ type: REMOVE_NOTIFICATION });
};

export const multipleInvoicesFund = (checkoutList) => (dispatch) => {
  dispatch({ type: INVOICE_MULTIPLE_FUND_REQ, payload: checkoutList });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      dispatch({ type: REMOVE_NOTIFICATION });
    }, 7000);

    fundMultipleInvoices(checkoutList)
      .then((data) => {
        if (data.error) {
          dispatch({ type: SHOW_NOTIFICATION_ERROR, payload: data });
          dispatch(listApprovedInvoices());
          dispatch({
            type: INVOICE_MULTIPLE_FUND_FAILED,
            payload: { data },
            isError: true,
          });
          resolve();
        } else {
          dispatch({ type: INVOICE_MULTIPLE_FUND_SUCCESS, payload: data });
          dispatch({
            type: SHOW_NOTIFICATION_SUCCESS,
            payload: {
              fullFunding: data.length === checkoutList.invoices.length,
            },
          });
          dispatch(listApprovedInvoices());
          dispatch(getWalletStatus());
          resolve();
        }
      })
      .catch((err) => {
        dispatch({ type: SHOW_NOTIFICATION_ERROR, payload: { err } });
        dispatch({
          type: INVOICE_MULTIPLE_FUND_FAILED,
          payload: { err },
          isError: true,
        });
        reject(err);
      });
  });
};

export const invoiceFund = (id, amount, rate) => (dispatch) => {
  dispatch({ type: INVOICE_FUND_REQ, payload: id });
  return new Promise((resolve, reject) => {
    fundInvoice(id, amount, rate)
      .then(() => {
        dispatch({ type: INVOICE_FUND_SUCCESS });
        dispatch(listApprovedInvoices());
        dispatch(getWalletStatus());
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: INVOICE_FUND_FAILED,
          payload: err,
          isError: true,
        });
        reject(err);
      });
  });
};

export const addInvoiceToCart = (id, amount) => (dispatch) => {
  dispatch({ type: INVOICE_ADD_TO_CART, payload: { id, amount } });
  dispatch({ type: INVOICE_ADD_TO_CART_SUCCESS, payload: { id, amount } });
};
