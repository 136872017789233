import React from 'react';
import { FormattedMessage } from 'react-intl';

const English = 'English';
const Dutch = 'Nederlandse';
const French = 'Française';
const Hebrew = 'עברית';
const en = 'en';
const fr = 'fr';
const nl = 'nl';
const he = 'he';

export const locales = {
  en,
  fr,
  nl,
  he,
};

const languageTranslation = {
  English: (
    <FormattedMessage id={'language.english'} defaultMessage={English} />
  ),
  Dutch: <FormattedMessage id={'language.dutch'} defaultMessage={Dutch} />,
  French: <FormattedMessage id={'language.french'} defaultMessage={French} />,
  Hebrew: <FormattedMessage id={'language.hebrew'} defaultMessage={Hebrew} />,
};

export const selectableLanguages = [
  { id: locales.en, displayText: languageTranslation.English },
  { id: locales.he, displayText: languageTranslation.Hebrew },
];
// : [
//     { id: locales.en, displayText: languageTranslation.English },
//     { id: locales.nl, displayText: languageTranslation.Dutch },
//     { id: locales.fr, displayText: languageTranslation.French },
//     { id: locales.he, displayText: languageTranslation.Hebrew }
// ];
