import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { Router } from 'react-router';
import ConnectedIntlProvider from './ConnectedIntlProvider/connectedIntlProvider';
import App from './App/App.container';

// if (process.env.NODE_ENV === 'production') {
//     import DevTools from './DevTools';
// }

export default function Root({ store, history }) {
  return (
    <Provider store={store}>
      <ConnectedIntlProvider>
        <Router history={history}>
          <Route path='/' component={App} />
        </Router>
      </ConnectedIntlProvider>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
