import axios from 'axios';
import interceptors from './interceptors';
import { appConfig, endPoints } from '../config/config';
import Auth0Helper from '../components/Utils/Auth0Helper';

// Some endpoints should not recieve any authentication token:
// - Login endpoint
// - Create user endpoint
const tokenlessClinet = axios.create({
  baseURL: endPoints.user,
  timeout: 10000,
});

const instance = axios.create({
  baseURL: endPoints.user,
  timeout: 10000,
});

const newPlatformInstance = axios.create({
  baseURL: endPoints.investor,
  timeout: 10000,
});

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

instance.interceptors.request.use(interceptors.authInterceptor);
instance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

newPlatformInstance.interceptors.request.use(interceptors.authInterceptor);
newPlatformInstance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

export const allUsers = () => instance.get('/all').then((res) => res.data);

export const captchaStatus = () => instance.get('/captcha-disabled');

export const login = (creds) =>
  tokenlessClinet.post('/auth', creds).then((res) => {
    return res.data;
  });

export const create = (user) => {
  return newPlatformInstance.post('/signup', user).then((res) => {
    return res.data;
  });
};

// eslint-disable-next-line consistent-return
export const userFromToken = (token) => {
  if (appConfig.isNewPlatform) {
    if (Auth0Helper.isAuthenticated()) {
      return newPlatformInstance
        .post('/getInvestorDetails', axiosConfig)
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.data.investor));
          return res.data.investor;
        });
    }
    return Promise.reject();
  }
  return instance.get('/auth').then((res) => {
    return res.data;
  });
};

export const acceptConsent = () => {
  return newPlatformInstance
    .post(
      '/acceptCookieConsent',
      { cookiePolicyVersion: appConfig.cookiePolicyVersion },
      axiosConfig
    )
    .then((res) => res.data);
};

export const getConfigurations = (data) =>
  newPlatformInstance
    .post('/getInvestorConfigurations', axiosConfig)
    .then((res) => {
      return res.data;
    });

export const resetPassword = (data) =>
  instance.post('/reset', data).then((res) => {
    return res.data;
  });

export const updateUser = (param, body) =>
  instance.put(`/`, body).then((res) => {
    return res.data;
  });

export const getKycLink = (param, body) =>
  instance.get(`/kyclink`, body).then((res) => {
    return res.data;
  });

export const appVersion = (param, body) =>
  axios.get(`/meta.json`, body).then((res) => {
    return res.data;
  });

export const walletStatus = () => {
  if (appConfig.isNewPlatform) {
    return newPlatformInstance
      .post('/getBalance/', {}, axiosConfig)
      .then((res) => {
        return res.data;
      });
  }

  return instance.get('/wallet/status').then((res) => {
    return res.data;
  });
};

export const withdraw = (data) =>
  instance.post('/withdraw', data).then((res) => {
    return res.data;
  });

export const resendEmailActivation = (emailAddress) =>
  instance.get(`/resend/${emailAddress}`).then((res) => {
    return res.data;
  });
export const sendResetPasswordEmail = (emailAddress, body) =>
  instance.get(`/reset/${emailAddress}`, body).then((res) => {
    return res.data;
  });

// TODO: wait for code review
export const changePassword = (data) =>
  instance.put(`/${data.id}/change-password-ex`, data).then((res) => {
    return res.data;
  });

export const getTaxInvoiceDocument = (document) => {
  return instance
    .get(`/taxinvoice/${document}`, { responseType: 'blob' })
    .then((res) => {
      return res.data;
    });
};
