import { getAllEnumerations } from '../../services/common.service';

export const GET_ENUMERATIONS_REQUEST = 'GET_ENUMERATIONS_REQUEST';
export const GET_ENUMERATIONS_SUCCESS = 'GET_ENUMERATIONS_SUCCESS';
export const GET_ENUMERATIONS_FAILED = 'GET_ENUMERATIONS_FAILED';

export const getEnumerations = () => (dispatch, getState) => {
  dispatch({ type: GET_ENUMERATIONS_REQUEST });
  return getAllEnumerations()
    .then((response) => {
      dispatch({ type: GET_ENUMERATIONS_SUCCESS, payload: response });
    })
    .catch((err) => {
      dispatch({
        type: GET_ENUMERATIONS_FAILED,
        payload: [],
      });
    });
};
