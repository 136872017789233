import React from 'react';
import { render } from 'react-dom';
// import { AppContainer } from "react-hot-loader";
import { configureStore, history } from './redux/store/configureStore';
import Root from './components/Root';
import ReactGA from 'react-ga';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nl from 'react-intl/locale-data/nl';
import fr from 'react-intl/locale-data/fr';
import he from 'react-intl/locale-data/he';

import { appConfig } from './config/config';
import packageJson from '../package.json';

import auth0 from 'auth0-js';

export const store = configureStore();

ReactGA.initialize(appConfig.googleAnalyticsKey);

addLocaleData([...en, ...nl, ...fr, ...he]);

// Render our root component into the div with id "root"
// We select the messages to pass to IntlProvider based on the user's locale

if (appConfig.isNewPlatform) {
  window.webAuth = new auth0.WebAuth(window.auth0);
}
window.appVersion = packageJson.version;

render(
  <Root store={store} history={history} />,
  document.getElementById('root')
);
