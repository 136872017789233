import { connect } from 'react-redux';
import LogEntriesErrorBoundary from './LogEntriesErrorBoundary';

const mapStateToProps = (state) => {
  return {
    user: state.auth.get('user') ? state.auth.get('user').toJS() : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogEntriesErrorBoundary);
