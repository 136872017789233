import axios from 'axios';
import { appConfig, endPoints } from '../config/config';
import interceptors from './interceptors';

const instance = axios.create({
  baseURL: endPoints.kyc,
  timeout: 10000,
});

const newPlatformInstance = axios.create({
  baseURL: endPoints.investor,
  timeout: 10000,
});

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

newPlatformInstance.interceptors.request.use(interceptors.authInterceptor);
newPlatformInstance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

instance.interceptors.request.use(interceptors.authInterceptor);
instance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

export const kycDocUpload = (data, args) =>
  instance
    .post(`/docs/${data.get('type')}`, data, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        args.onProgress(percentCompleted);
      },
      timeout: 0,
    })
    .then((res) => res.data);

export const getAllKycDocs = (data) =>
  instance.get('/docs', data).then((res) => {
    return res.data;
  });

export const kycDeleteDoc = (fileType) =>
  instance.delete(`/docs/${fileType}`).then((res) => {
    return res.data;
  });

export const kycSubmit = (currentFinancialEntityId) =>
  instance.put(`/verify/${currentFinancialEntityId}`);

export const getInvestorKyc = () =>
  newPlatformInstance.post(`/getInvestorKyc`, {}, appConfig).then((res) => {
    return res.data;
  });

export const submitInvestorKyc = (data) => {
  let formData = new FormData();
  formData.append('file', data, 'signature');

  const dataToSend = {
    cookiePolicyVersion: appConfig.cookiePolicyVersion,
  };

  formData.append(
    'KycSigningRequestDto',
    new Blob([JSON.stringify(dataToSend)], { type: 'application/json' })
  );

  return newPlatformInstance
    .post(`/submitInvestorKyc`, formData, appConfig)
    .then((res) => {
      return res.data;
    });
};

export const getInvestorDocuments = () =>
  newPlatformInstance
    .post(`/getInvestorDocuments`, {}, appConfig)
    .then((res) => {
      return res.data;
    });

export const uploadInvestorDocument = (data) => {
  let formData = new FormData();
  formData.append('file', data.file, data.file.name);

  const dataToSend = {
    investorId: data.investorId,
    documentType: data.documentType,
    contentType: data.file.type,
  };

  formData.append(
    'uploadInvestorDocumentRequest',
    new Blob([JSON.stringify(dataToSend)], { type: 'application/json' })
  );

  return newPlatformInstance
    .post(`/uploadInvestorDocument`, formData, appConfig)
    .then((res) => {
      return res.data;
    });
};

export const updateInvestorKyc = (data) =>
  newPlatformInstance
    .post(`/updateInvestorKyc`, data, appConfig)
    .then((res) => {
      return res.data;
    });
