import { fromJS } from 'immutable';
import * as investorDashboardActions from '../actions/investorDashboard.actions';
import * as userActions from '../actions/user.actions';
const initialState = fromJS({
  investorDashboardData: {},
  // ,assets: [], transactions: []
});

const getInvestorDashboardData = (state = initialState, action) => {
  switch (action.type) {
    case userActions.LOGOUT:
      return state.withMutations((st) => {
        st.set('investorDashboardData', fromJS({}));
        st.set('transactions', []);
        st.set('assets', []);
      });
    /*
        case investorDashboardActions.GET_INVESTOR_DASHBOARD_DATA_STARTED:
            return state.set("getInvestorDashboardDataStarted", true);
        case investorDashboardActions.GET_INVESTOR_DASHBOARD_DATA_FAILED:
            return state.set("getInvestorDashboardDataFailed", fromJS(action.payload));
        case investorDashboardActions.GET_INVESTOR_DASHBOARD_DATA_SUCCESS:
            return state.withMutations(st => {
                st.set("investorDashboardData", fromJS(action.data.investorData));
                st.set("transactions", action.data.transactions);
                st.set("footerLoader", false);
                st.set("assets", action.data.assets);
            });
*/
    case investorDashboardActions.CASH_OUT:
    case investorDashboardActions.CASH_OUT_SUCCESS:
      return state.set('error', null);
    case investorDashboardActions.CASH_OUT_FAILURE:
      return state.set('error', action.isError);

    case investorDashboardActions.TOTALS_DATA_LOADING:
      return state.set('isTotalsLoading', true);
    case investorDashboardActions.TOTALS_DATA_SUCCESS:
      return state.withMutations((st) => {
        st.set('investorDashboardData', action.payload.data);
        st.set('isTotalsError', false);
        st.set('isTotalsLoading', false);
      });
    case investorDashboardActions.TOTALS_DATA_FAILURE:
      return state.withMutations((st) => {
        st.set('isTotalsError', true);
        st.set('isTotalsLoading', false);
      });

    case investorDashboardActions.TRANSACTIONS_DATA_LOADING:
      return state.set('isTransactionsLoading', true);
    case investorDashboardActions.TRANSACTIONS_DATA_SUCCESS:
      return state.withMutations((st) => {
        st.set('transactions', action.payload.data);
        st.set('isTransactionsError', false);
        st.set('isTransactionsLoading', false);
      });
    case investorDashboardActions.TRANSACTIONS_DATA_FAILURE:
      return state.withMutations((st) => {
        st.set('isTransactionsError', true);
        st.set('isTransactionsLoading', false);
      });

    case investorDashboardActions.ASSETS_DATA_LOADING:
      return state.set('isAssetsLoading', true);
    case investorDashboardActions.ASSETS_DATA_SUCCESS:
      return state.withMutations((st) => {
        st.set('assets', action.payload.data);
        st.set('isAssetsError', false);
        st.set('isAssetsLoading', false);
      });
    case investorDashboardActions.ASSETS_DATA_FAILURE:
      return state.withMutations((st) => {
        st.set('isAssetsError', true);
        st.set('isAssetsLoading', false);
      });
  }
  return state;
};

export default getInvestorDashboardData;
