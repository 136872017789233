import IntlMessageFormat from 'intl-messageformat';
import { appConfig } from '../../config/config';

// Our translated strings
import enLocales from '../../lang/locales/en.json';
import frLocales from '../../lang/locales/fr.json';
import nlLocales from '../../lang/locales/nl.json';
import heLocales from '../../lang/locales/he.json';

const localeData = [];
localeData['en'] = enLocales;
localeData['fr'] = frLocales;
localeData['nl'] = nlLocales;
localeData['he'] = heLocales;

const translator = (() => {
  return {
    getLocale() {
      return (
        this.locale ||
        localStorage.getItem('locale') ||
        appConfig.defaultLanguage
      );
    },

    setLocale(locale) {
      // eslint-disable-next-line no-return-assign
      return (this.locale = locale);
    },

    /**
     * Receives a translation id and returns the translated string based on current locale.
     * @param {Object} translation - translation info, including id etc.
     * @param {string} locale = current langauge (en/fr/nl/he).
     */
    translationString(translation) {
      const locale = this.getLocale();
      try {
        const _translation = new IntlMessageFormat(
          localeData[locale][`${translation.id}`]
        );
        return _translation.format(translation.values || {});
      } catch (e) {
        if (translation.defaultMessage) {
          const _fallback = new IntlMessageFormat(translation.defaultMessage);
          return _fallback.format(translation.values || {});
        }
        return '';
      }
    },
  };
})();

export default translator;
