// const baseUrl = "http://localhost:3130/";
const baseUrl = 'https://wrapperdev.creditplace.co.il/';
// const baseUrl = "https://cem.dev.cem-orchestration.creditplace.com:443/";

export const navigationURLs = require('../appPaths').navigationURLs;

export const endPoints = {
  baseUrl,
  invoice: `${baseUrl}api/invoice`,
  kyc: `${baseUrl}api/kyc`,
  investorDashboard: `${baseUrl}api/investor_dashboard`,
  user: `${baseUrl}api/user`,
  investor: window.endPoints.investor,
  collector: window.endPoints.collector,
};

export const appConfig = window.appConfigs || {
  isNewPlatform: true,
  currencySymbol: '₪',
  defaultLanguage: 'he',
  googleAnalyticsKey: 'UA-77862577-6',
  showExpectedProfit: true,
};

export const registration = {
  nameMinimumChars: 2,
  nameMaximumChars: 50,
  passwordLengthMin: 8,
  passwordLengthMax: 50,
  minimumDigitsInPhoneNumber: 8,
  maximumDigitsInPhoneNumber: 25,
  googleCaptchaSiteKey: '6LeuiDgUAAAAAOMZPf1xdcUwIDmy3D5T8jArDKqF',
};

export const privacyPolicyLink = window.privacyPolicyLink || {
  en: '#',
  du: '#',
  fr: '#',
  he: '#',
};

export const termsAndConditionsLink = window.termsAndConditionsLink || {
  en: '#',
  du: '#',
  fr: '#',
  he: '#',
};

export const investorAgreementLink = window.investorAgreementLink || {
  en: '#',
  du: '#',
  fr: '#',
  he: '#',
};

export const permLink = window.permLink || {
  en: '#',
  du: '#',
  fr: '#',
  he: '#',
};
