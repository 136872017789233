import {
  kycDocUpload,
  getAllKycDocs,
  kycDeleteDoc,
  kycSubmit,
  getInvestorKyc,
  getInvestorDocuments,
  uploadInvestorDocument,
  updateInvestorKyc,
  submitInvestorKyc,
} from '../../services/kyc.service';
// import {getInvestorKyc} from "../../services/user.service";

export const ALL_KYC_DOCS_STARTED = 'ALL_KYC_DOCS_STARTED';
export const ALL_KYC_DOCS_SUCCESS = 'ALL_KYC_DOCS_SUCCESS';
export const ALL_KYC_DOCS_FAILED = 'ALL_KYC_DOCS_FAILED';
export const KYC_DOC_UPLOAD_STARTED = 'KYC_DOC_UPLOAD_STARTED';
export const KYC_DOC_UPLOAD_SUCCESS = 'KYC_DOC_UPLOAD_SUCCESS';
export const KYC_DOC_UPLOAD_FAILED = 'KYC_DOC_UPLOAD_FAILED';
export const KYC_DOC_DELETE_STARTED = 'KYC_DOC_DELETE_STARTED';
export const KYC_DOC_DELETE_FAILED = 'KYC_DOC_DELETE_FAILED';
export const KYC_DOC_DELETE_SUCCESS = 'KYC_DOC_DELETE_SUCCESS';
export const KYC_STATUS_CHANGED = 'KYC_STATUS_CHANGED';
export const KYC_SUBMIT_START = 'KYC_SUBMIT_START';
export const KYC_SUBMIT_SUCCESS = 'KYC_SUBMIT_SUCCESS';

export const KYC_SUBMIT_PROGRESS = 'KYC_SUBMIT_PROGRESS';
export const KYC_SUBMIT_DONE = 'KYC_SUBMIT_DONE';
export const KYC_SUBMIT_FAILED = 'KYC_SUBMIT_FAILED';

export const KYC_UPLOAD_PROGRESS = 'KYC_UPLOAD_PROGRESS';
export const KYC_GET_PROGRESS = 'KYC_GET_PROGRESS';
export const KYC_GET_SUCCESS = 'KYC_GET_SUCCESS';
export const KYC_GET_FAILED = 'KYC_GET_FAILED';
export const DOC_GET_PROGRESS = 'DOC_GET_PROGRESS';
export const DOC_GET_SUCCESS = 'DOC_GET_SUCCESS';
export const DOC_GET_FAILED = 'DOC_GET_FAILED';
export const DOC_UPLOAD_PROGRESS = 'DOC_UPLOAD_PROGRESS';
export const DOC_UPLOAD_SUCCESS = 'DOC_UPLOAD_SUCCESS';
export const DOC_UPLOAD_FAILED = 'DOC_UPLOAD_FAILED';
export const KYC_UPDATE_PROGRESS = 'KYC_UPDATE_PROGRESS';
export const KYC_UPDATE_SUCCESS = 'KYC_UPDATE_SUCCESS';
export const KYC_UPDATE_FAILED = 'KYC_UPDATE_FAILED';

export const allKycDocs = () => (dispatch) => {
  dispatch({ type: ALL_KYC_DOCS_STARTED, payload: {} });
  return getAllKycDocs()
    .then((response) => {
      dispatch({ type: ALL_KYC_DOCS_SUCCESS, payload: response });
    })
    .catch((err) => {
      dispatch({
        type: ALL_KYC_DOCS_FAILED,
        payload: err,
        isError: true,
      });
    });
};

const serializeFile = (f) => {
  const file = f.get('file');
  return { name: file.name, size: file.size };
};

export const kycStatusChanged = (newStatus) => (dispatch) =>
  dispatch({ type: KYC_STATUS_CHANGED, newStatus });

export const deleteFile = (fileType) => (dispatch) => {
  dispatch({ type: KYC_DOC_DELETE_STARTED, fileType: fileType });
  return kycDeleteDoc(fileType);
};
export const deleteFileSuccess = (response, fileType) => (dispatch) =>
  dispatch({
    type: KYC_DOC_DELETE_SUCCESS,
    response: response,
    fileType: fileType,
  });

export const deleteFileFail = (response, fileType) => (dispatch) =>
  dispatch({
    type: KYC_DOC_DELETE_FAILED,
    err: response,
    fileType: fileType,
  });

export const uploadFile = (fileData, fileType) => (dispatch) => {
  dispatch({
    type: KYC_DOC_UPLOAD_STARTED,
    payload: serializeFile(fileData),
    fileType: fileType,
  });
  return kycDocUpload(fileData, {
    onProgress: (percentCompleted) => {
      dispatch({
        type: KYC_UPLOAD_PROGRESS,
        percentCompleted: percentCompleted,
        fileType: fileType,
      });
    },
  });
};

export const uploadSuccess = (response, fileType) => (dispatch) =>
  dispatch({
    type: KYC_DOC_UPLOAD_SUCCESS,
    response: response,
    fileType: fileType,
  });

export const uploadFail = (response, fileType) => (dispatch) =>
  dispatch({
    type: KYC_DOC_UPLOAD_FAILED,
    err: response,
    fileType: fileType,
    isError: true,
  });

export const submit = (currentFinancialEntityId) => (dispatch) => {
  dispatch({ type: KYC_SUBMIT_START });
  return kycSubmit(currentFinancialEntityId);
};

export const submitSuccess = (response) => (dispatch) =>
  dispatch({ type: KYC_SUBMIT_SUCCESS, response });

export const getKyc = () => (dispatch) => {
  dispatch({ type: KYC_GET_PROGRESS, payload: {} });
  return getInvestorKyc()
    .then((response) => {
      if (response.success) {
        dispatch({ type: KYC_GET_SUCCESS, payload: response.investorKYC });
      } else {
        dispatch({ type: KYC_GET_FAILED, payload: {} });
      }
    })
    .catch((err) => dispatch({ type: KYC_GET_FAILED, payload: {} }));
};

export const submitKyc = (data) => (dispatch) => {
  dispatch({ type: KYC_SUBMIT_PROGRESS, payload: {} });
  return submitInvestorKyc(data)
    .then((response) => {
      if (response.success) {
        dispatch({ type: KYC_SUBMIT_DONE, payload: response.agreementPdfLink });
      } else {
        dispatch({ type: KYC_SUBMIT_FAILED, payload: {} });
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: KYC_SUBMIT_FAILED, payload: {} });
      return Promise.reject(err);
    });
};

export const getInvestorDocs = () => (dispatch) => {
  dispatch({ type: DOC_GET_PROGRESS, payload: {} });
  return getInvestorDocuments()
    .then((response) => {
      if (response.success) {
        dispatch({
          type: DOC_GET_SUCCESS,
          payload: response.investorDocuments,
        });
      } else {
        dispatch({ type: DOC_GET_FAILED, payload: {} });
      }
    })
    .catch((err) => dispatch({ type: DOC_GET_FAILED, payload: {} }));
};

export const uploadInvestorDoc = (data) => (dispatch) => {
  dispatch({ type: DOC_UPLOAD_PROGRESS, payload: {} });
  return uploadInvestorDocument(data)
    .then((response) => {
      if (response.success) {
        // dispatch(getInvestorDocs())
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateKyc = (data) => (dispatch) => {
  dispatch({ type: KYC_UPDATE_PROGRESS, payload: {} });

  return updateInvestorKyc({ investorKycDto: data })
    .then((response) => {
      if (response.success) {
        dispatch({ type: KYC_UPDATE_SUCCESS, payload: data });
        dispatch(getKyc());
      } else {
        dispatch({ type: KYC_UPDATE_FAILED, payload: {} });
        return Promise.reject(response);
      }
      return response;
    })
    .catch((err) => {
      dispatch({ type: KYC_UPDATE_FAILED, payload: {} });
      return Promise.reject(err);
    });
};
