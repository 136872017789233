import axios from 'axios';
import interceptors from './interceptors';
import { appConfig, endPoints } from '../config/config';
import Auth0Helper from '../components/Utils/Auth0Helper';

const instance = axios.create({
  baseURL: endPoints.invoice,
  timeout: 10000,
});

const newPlatformInstance = axios.create({
  baseURL: endPoints.investor,
  timeout: 10000,
});

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

instance.interceptors.request.use(interceptors.authInterceptor);
instance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

newPlatformInstance.interceptors.request.use(interceptors.authInterceptor);
newPlatformInstance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

export const allInvoices = () => instance.get('/all').then((res) => res.data);

export const approvedInvoices = (lang) => {
  if (appConfig.isNewPlatform) {
    return newPlatformInstance
      .post('/getMarketPlace', {}, axiosConfig)
      .then((res) => res.data);
  }
  return instance
    .get('/marketplace', { params: { language: lang } })
    .then((res) => res.data);
};
export const getInvoiceDetails = (id) =>
  instance.get(`/${id}`).then((res) => res.data);
export const fundInvoice = (id, amount, rate) =>
  instance
    .post(`/${id}/fund`, { amount, rate }, { timeout: 30000 })
    .then((res) => res.data);
export const fundMultipleInvoices = (checkoutList) => {
  if (appConfig.isNewPlatform) {
    if (Auth0Helper.isAuthenticated()) {
      const assets = checkoutList.invoices.map((item) => {
        return { assetId: item.invoiceId, amount: item.amount };
      });
      return newPlatformInstance
        .post(
          '/buyAssets',
          { buyAssetRequests: assets },
          { ...axiosConfig, timeout: 30000 }
        )
        .then((res) => {
          return res.data.success && res.data.invoiceIds.length
            ? res.data.invoiceIds
            : { ...res, error: true };
        });
    }
    return Promise.reject();
  }
  return instance.post(`/buy`, checkoutList, { timeout: 30000 }).then((res) => {
    console.log(res.data);
    return res.data;
  });
};

export const createInvoice = (invoice) => {
  return instance.post('/', invoice).then((res) => res.data);
};
