import { push } from 'react-router-redux';

import {
  create,
  resetPassword,
  resendEmailActivation,
  captchaStatus,
  sendResetPasswordEmail,
} from '../../services/user.service';
import { appConfig, navigationURLs } from '../../config/config';

export const CREATE_NEW_CUSTOMER_SUBMITTED = 'CREATE_NEW_CUSTOMER_SUBMITTED';
export const CREATE_NEW_CUSTOMER_SUBMITTED_SUCCESS =
  'CREATE_NEW_CUSTOMER_SUBMITTED_SUCCESS';
export const CREATE_NEW_CUSTOMER_SUBMITTED_FAILURE =
  'CREATE_NEW_CUSTOMER_SUBMITTED_FAILURE';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';
export const INPUT_PASSWORD_CHANGED = 'INPUT_PASSWORD_CHANGED';
export const RETYPE_INPUT_PASSWORD_CHANGED = 'RETYPE_INPUT_PASSWORD_CHANGED';
export const CURRENT_PASSWORD_CHNAGED = 'CURRENT_PASSWORD_CHNAGED';

export const SEND_RESET_PASSWORD_SUCCESS = 'SEND_RESET_PASSWORD_SUCCESS';
export const SEND_RESET_PASSWORD_FAILURE = 'SEND_RESET_PASSWORD_FAILURE';

export const CLEAR_STATUS = 'CLEAR_STATUS';
export const CLEAR_PASSWORDS = 'CLEAR_PASSWORDS';
export const EXECUTE_GOOGLE_VERIFY = 'EXECUTE_GOOGLE_VERIFY';
export const CHANGE_GOOGLE_CAPTCHA_LANGUAGE = 'CHANGE_GOOGLE_CAPTCHA_LANGUAGE';
export const CAPTCHA_DISABLED = 'CAPTCHA_DISABLED';

export const createNewAccount = (user, url) => (dispatch) => {
  dispatch({ type: CREATE_NEW_CUSTOMER_SUBMITTED, payload: {} });
  const masterAgentId = localStorage.getItem('masteragentid');
  const agentId = localStorage.getItem('agentid');
  const userObj = {
    investor: { ...user, masteragentid: masterAgentId, agentid: agentId },
  };
  return create(userObj)
    .then((res) => {
      if (res.success) {
        dispatch(push(navigationURLs.emailConfirmation));
        dispatch({
          type: CREATE_NEW_CUSTOMER_SUBMITTED_SUCCESS,
          payload: user,
        });
      } else {
        const status =
          res &&
          res.message &&
          res.message !== 'InvestorCreateFailedDB' &&
          res.message !== 'InvestorCreateAuth0UserFailed'
            ? res.badFields.toString()
            : res &&
              res.message &&
              res.message === 'InvestorCreateAuth0UserFailed'
            ? 'EMAIL_EXISTS'
            : 'Error';
        dispatch({
          type: CREATE_NEW_CUSTOMER_SUBMITTED_FAILURE,
          payload: { user, status },
          isError: true,
        });
      }
    })
    .catch((err) => {
      const status =
        err &&
        err.response &&
        err.response.data &&
        err.response.data.error === 'EMAIL_EXISTS'
          ? err.response.data.error
          : 'Error';
      dispatch({
        type: CREATE_NEW_CUSTOMER_SUBMITTED_FAILURE,
        payload: { user, status },
        isError: true,
      });
    });
};

export const resendEmail = (userEmail) => (dispatch) => {
  dispatch({
    type: RESEND_EMAIL,
    payload: { status: '' },
  });
  return resendEmailActivation(userEmail)
    .then(() => {
      dispatch({
        type: RESEND_EMAIL_SUCCESS,
        payload: { status: 'resendEmailSuccess' },
      });
    })
    .catch((err) => {
      dispatch({
        type: RESEND_EMAIL_FAILURE,
        payload: { status: 'Error' },
        isError: true,
      });
    });
};

export const createPassword = (password, email, token) => (dispatch) => {
  dispatch({ type: CREATE_PASSWORD });
  const data = { password, token };
  return resetPassword(data)
    .then(() => {
      dispatch({
        type: CREATE_PASSWORD_SUCCESS,
        payload: { password, email, status: 'intializePasswordSuccess' },
      });
    })
    .catch((err) => {
      dispatch({
        type: CREATE_PASSWORD_SUCCESS,
        payload: {
          password,
          email,
          status: 'Error',
        },
        isError: true,
      });
    });
};

export const sendResetPassword = (email) => (dispatch) => {
  const language = localStorage.getItem('locale') || appConfig.defaultLanguage;
  const body = { language: language };
  return sendResetPasswordEmail(email, body)
    .then(() => {
      dispatch(push(navigationURLs.emailConfirmation));
      dispatch({
        type: SEND_RESET_PASSWORD_SUCCESS,
        payload: { email, status: 'resetPasswordSuccess' },
      });
    })
    .catch((err) => {
      dispatch({
        type: SEND_RESET_PASSWORD_FAILURE,
        payload: { err, status: 'Error' },
        isError: true,
      });
    });
};

export const clearRegisterStatus = () => (dispatch) =>
  dispatch({ type: CLEAR_STATUS, payload: null });

// from settings details
export const onChangePassword = (password) => (dispatch) => {
  dispatch({ type: INPUT_PASSWORD_CHANGED, payload: password });
};

export const onChangeRetypePassword = (password) => (dispatch) => {
  dispatch({ type: RETYPE_INPUT_PASSWORD_CHANGED, payload: password });
};

export const onChangeCurrentPassword = (password) => (dispatch) => {
  dispatch({ type: CURRENT_PASSWORD_CHNAGED, payload: password });
};
//
export const clearPasswordValues = () => (dispatch) =>
  dispatch({ type: CLEAR_PASSWORDS, payload: '' });

export const executeGoogleVerify = () => (dispatch) =>
  dispatch({ type: EXECUTE_GOOGLE_VERIFY, payload: null });

export const changeGoogleCaptchaLanguage = (language) => (dispatch) =>
  dispatch({ type: CHANGE_GOOGLE_CAPTCHA_LANGUAGE, payload: language });

export const isCaptchaDisabled = () => (dispatch) => {
  captchaStatus()
    .then((resp) => {
      dispatch({ type: CAPTCHA_DISABLED, payload: resp.data });
    })
    .catch((err) =>
      dispatch({ type: CAPTCHA_DISABLED, payload: { disabled: false } })
    );
};
