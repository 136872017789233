import React from 'react';
import PropTypes from 'prop-types';
import Routes from './routes';
import LogEntriesErrorBoundary from '../LogEntriesErrorBoundary/LogEntriesErrorBoundary.container';
import translator from '../../components/Utils/translationString';
import './App.scss';

import AppIdleTimer from './AppIdleTimer';
import qs from 'qs';
import { selectableLanguages } from '../../lang/config';
import { setPageTitle } from '../Utils/pageUtils';
import AppConsent from './AppConsent';
import PrComponent from './PrComponent';

export const getUrlParams = (path) =>
  !!window.location.search
    ? qs.parse(window.location.search.toLowerCase(), {
        ignoreQueryPrefix: true,
      })
    : qs.parse(window.location.hash.replace(`#${path}`, ''), {
        ignoreQueryPrefix: true,
      });

const rawParameters = getUrlParams('/');

const paramLang = (lang) => {
  if (selectableLanguages.some((item) => lang === item.id)) {
    console.log(rawParameters.lang);
    return rawParameters.lang;
  }
  return '';
};

const paramAgents = () => {
  rawParameters.masteragentid &&
    localStorage.setItem('masteragentid', rawParameters.masteragentid);
  rawParameters.agentid &&
    localStorage.setItem('agentid', rawParameters.agentid);
};

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    paramAgents();
    if (paramLang(rawParameters.lang))
      this.props.changeLocale(rawParameters.lang);
    this.props.loadUserFromToken();
  }

  render() {
    const lang = translator.getLocale();
    const isRtl = lang.indexOf('he') === 0 || lang.indexOf('ar') === 0;

    if (!this.props.doneTokenAuth) return <div />;

    document.documentElement.lang = translator.getLocale();
    document.documentElement.dir = isRtl ? 'rtl' : 'ltr';
    document.body.classList.remove('isRtl');
    setPageTitle('webapp.cim.title');

    if (isRtl) document.body.classList.add('isRtl');

    return (
      <LogEntriesErrorBoundary>
        <AppIdleTimer>
          <div className={`body`}>
            <AppConsent>{Routes}</AppConsent>
            <PrComponent />
          </div>
        </AppIdleTimer>
      </LogEntriesErrorBoundary>
    );
  }
}

App.propTypes = {
  doneTokenAuth: PropTypes.bool,
  loadUserFromToken: PropTypes.func.isRequired,
  changeLocale: PropTypes.func.isRequired,
};

export default App;
