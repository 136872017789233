import { push } from 'react-router-redux';
import {
  allUsers,
  login,
  userFromToken,
  updateUser,
  walletStatus,
  changePassword,
  getKycLink,
  getConfigurations,
  appVersion,
  acceptConsent,
} from '../../services/user.service';
import { appConfig, navigationURLs } from '../../config/config';
import Auth0Helper from '../../components/Utils/Auth0Helper';
import { getEnumerations } from '../actions/common.actions';

export const RESET_STATUS = 'RESET_STATUS';

export const LOGIN_REQ = 'LOGIN_REQ';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';

export const LOGIN_GET_USERS_REQ = 'LOGIN_GET_USERS_REQ';
export const LOGIN_GET_USERS_SUCCESS = 'LOGIN_GET_USERS_SUCCESS';
export const LOGIN_GET_USERS_FAILED = 'LOGIN_GET_USERS_FAILED';

export const USER_FROM_TOKEN_REQ = 'USER_FROM_TOKEN_REQ';
export const USER_FROM_TOKEN_SUCCESS = 'USER_FROM_TOKEN_SUCCESS';
export const USER_FROM_TOKEN_FAILED = 'USER_FROM_TOKEN_FAILED';

export const USER_CONFIG_SUCCESS = 'USER_CONFIG_SUCCESS';
export const USER_CONFIG_FAILED = 'USER_CONFIG_FAILED';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_LOCALE_FAILED = 'CHANGE_LOCALE_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const GET_BALANCE_REQ = 'GET_BALANCE_REQ';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAILED = 'GET_BALANCE_FAILED';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export const KYC_LINK_SUCCESS = 'KYC_LINK_SUCCESS';
export const KYC_LINK_FAILED = 'KYC_LINK_FAILED';

export const doLogin = (credentials) => (dispatch) => {
  dispatch({ type: LOGIN_REQ });
  return login(credentials)
    .then((userData) => {
      dispatch(getEnumerations());
      userData.currentFinancialEntity = userData.financialEntities[0];
      const isRegularInvestor =
        userData.currentFinancialEntity.isInvestor &&
        !userData.currentFinancialEntity.isSeedInvestor;
      const isSeedInvestor = userData.currentFinancialEntity.isSeedInvestor;
      console.log('AUTH', { isRegularInvestor, isSeedInvestor });
      if (userData.id) {
        localStorage.removeItem('masteragentid');
        localStorage.removeItem('agentid');
        dispatch({ type: LOGIN_SUCCESS, payload: userData });
      } else
        dispatch({
          type: LOGIN_FAILED,
          payload: 'UNAUTHORIZED_USER',
          isError: true,
        });
    })
    .catch((err) => {
      const status =
        err &&
        err.response &&
        err.response.data &&
        err.response.data.error === 'INVALID_CREDENTIALS'
          ? err.response.data.error
          : 'Error';
      dispatch({
        type: LOGIN_FAILED,
        payload: status,
        isError: true,
      });
    });
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: LOGIN_GET_USERS_REQ });
  return allUsers()
    .then((users) => {
      dispatch({ type: LOGIN_GET_USERS_SUCCESS, payload: users });
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_GET_USERS_FAILED,
        error: err,
        isError: true,
      });
    });
};

export const getUserFromToken = (token) => (dispatch) => {
  dispatch({ type: USER_FROM_TOKEN_REQ });
  return userFromToken(token)
    .then((user) => {
      let kycStatus = 'new';
      switch (user.investorStatus) {
        case 'Registered':
          kycStatus = 'new';
          break;
        case 'Partial_KYC':
          kycStatus = 'fix-new';
          break;
        case 'Waiting_For_Review':
          kycStatus = 'pending';
          break;
        case 'Approved':
          user.kycApproved ? (kycStatus = 'verified') : (kycStatus = 'renew');
          break;
        case 'Active':
          user.kycApproved ? (kycStatus = 'verified') : (kycStatus = 'renew');
          break;
        default:
          kycStatus = 'verified';
        // todo verify also statuses Deleted and Locked
      }

      if (appConfig.isNewPlatform) {
        // adjust model
        user.currentFinancialEntity = {
          isInvestor: user.investorType === 'Regular',
          isSeedInvestor: user.investorType === 'Seed',
          id: user.investorId,
          kycStatus: kycStatus,
        };
        user.id = user.investorId;
        user.isQualified = !!user.certified;
        user.phone = user.phoneNumber;
        user.zip = user.zipCode;
        user.address = user.street;
        dispatch({ type: USER_FROM_TOKEN_SUCCESS, payload: user });
        dispatch(getEnumerations());
        return getConfigurations()
          .then((config) => {
            dispatch({ type: USER_CONFIG_SUCCESS, payload: config });
          })
          .catch((err) => {
            console.error("can't get user configurations");
            dispatch({ type: USER_CONFIG_FAILED, payload: err });
          });
      } else {
        user.currentFinancialEntity = user.financialEntities[0];
        dispatch({ type: USER_FROM_TOKEN_SUCCESS, payload: user });
      }
    })
    .catch((err) => {
      if (appConfig.isNewPlatform && Auth0Helper.isAuthenticated()) {
        console.error("can't get user data from token");
        Auth0Helper.logout();
      }

      dispatch({
        type: USER_FROM_TOKEN_FAILED,
        error: err,
        isError: true,
      });
    });
};

export const goToLogin = () => (dispatch) => {
  dispatch(push(navigationURLs.login));
};

export const goToRegistration = () => (dispatch) => {
  dispatch(push(navigationURLs.register));
};

export const logOut = () => (dispatch) => {
  if (appConfig.isNewPlatform) {
    Auth0Helper.logout();
  }

  dispatch({ type: LOGOUT, payload: {} });
};

export const changeLocale = (locale) => (dispatch) => {
  return dispatch({ type: CHANGE_LOCALE, payload: locale });
};

export const userAcceptConsent = () => (dispatch) => {
  return acceptConsent().then((res) => {
    dispatch(getUserFromToken(sessionStorage.getItem('token')));
  });
};

export const userUpdate = (body, userId) => (dispatch) => {
  const data = body;
  data.id = userId;
  if (appConfig.isNewPlatform) {
    return dispatch({ type: CHANGE_LOCALE, payload: body.lang });
  }
  return updateUser({ id: userId }, data)
    .then((response) => {
      dispatch({ type: UPDATE_USER, payload: response });
      if (body.lang && response.lang === body.lang) {
        dispatch({ type: CHANGE_LOCALE, payload: body.lang });
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATE_USER_FAILED, payload: err, isError: true })
    );
};

export const getUserKycLink = () => (dispatch) => {
  return getKycLink()
    .then((response) => {
      dispatch({ type: KYC_LINK_SUCCESS, payload: response });
    })
    .catch((err) =>
      dispatch({ type: KYC_LINK_FAILED, payload: err, isError: true })
    );
};

export const userAgreedToTerms = () => (dispatch) => {
  // return updateUser()
};

export const getWalletStatus = () => (dispatch) => {
  dispatch({ type: GET_BALANCE_REQ });
  return walletStatus()
    .then((ws) => {
      dispatch({ type: GET_BALANCE_SUCCESS, payload: ws });
    })
    .catch((err) => {
      dispatch({ type: GET_BALANCE_FAILED, error: err, isError: true });
    });
};

export const GET_VERSION_REQ = 'GET_VERSION_REQ';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAILED = 'GET_VERSION_FAILED';

export const getAppVersion = () => (dispatch) => {
  dispatch({ type: GET_VERSION_REQ });
  return appVersion()
    .then((appVersion) => {
      dispatch({ type: GET_VERSION_SUCCESS, payload: appVersion });
    })
    .catch((err) => {
      dispatch({ type: GET_VERSION_FAILED, error: err, isError: true });
    });
};

export const goToForgetPassword = () => (dispatch) =>
  dispatch(push(navigationURLs.forgotPassword));

export const changePasswordForExistingUser = (body) => (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD_REQUEST, payload: 'loading' });
  const data = {
    cur_password: body.currentPassword,
    id: body.id,
    password: body.newPassword,
  };
  return changePassword(data)
    .then((response) => {
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: 'success' });
    })
    .catch((err) => {
      dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: 'failure' });
    });
};

export const resetUserStatus = () => (dispatch) =>
  dispatch({ type: RESET_STATUS });

export const clearLoginError = () => (dispatch) =>
  dispatch({ type: CLEAR_LOGIN_ERROR, payload: '' });
