import { fromJS } from 'immutable';
import * as userActions from '../actions/user.actions';
import { appConfig } from '../../config/config';

const initialState = fromJS({ user: null, token: '' });

export const userReducer = (
  state = fromJS({
    ...initialState,
    ...{ locale: localStorage.getItem('locale') || appConfig.defaultLanguage },
  }),
  action
) => {
  // get users
  switch (action.type) {
    case userActions.LOGIN_GET_USERS_SUCCESS:
      console.log('SUCCESS');
      return state.set('allUsers', fromJS(action.payload));
    case userActions.LOGIN_GET_USERS_FAILED:
      console.log('ERROR', action.payload);
      return state.set('getUsersError', fromJS(action.payload));
  }

  // get user from token
  switch (action.type) {
    // here
    case userActions.USER_FROM_TOKEN_SUCCESS:
      const user = fromJS(action.payload);
      return state.withMutations((st) => {
        st.set('user', user);
        st.set('doneTokenAuth', true);
      });
    case userActions.USER_FROM_TOKEN_FAILED:
      return state.withMutations((st) => {
        st.set('doneTokenAuth', true);
      });
    case userActions.USER_CONFIG_SUCCESS:
      return state.withMutations((st) => {
        st.setIn(
          ['user', 'currentFinancialEntity', 'minPurchaseAmount'],
          action.payload.minPurchasableAmount
        );
        st.setIn(
          ['user', 'currentFinancialEntity', 'forceThreshold'],
          action.payload.forceThreshold
        );
        st.setIn(
          ['user', 'currentFinancialEntity', 'minimalLeftover'],
          action.payload.minimalLeftover
        );
      });
  }

  // do login
  switch (action.type) {
    case userActions.GET_VERSION_REQ:
      return state.set('isVesionLoading', true);

    case userActions.GET_VERSION_SUCCESS:
      return state.withMutations((st) => {
        st.set('isVesionLoading', false);
        st.set('appVersion', action.payload.version);
      });

    case userActions.GET_VERSION_FAILED:
      return state.withMutations((st) => {
        st.set('isVesionLoading', false);
        //  st.set("appVersion", action.payload.version);
      });

    case userActions.LOGIN_REQ:
      return state.set('isLoading', true);
    case userActions.LOGIN_SUCCESS:
      const user = fromJS(action.payload);

      // set the token in the local storage for the auth
      sessionStorage.setItem('token', user.get('token'));
      // set the user email in local storage for auto population
      sessionStorage.setItem('email', user.get('email'));
      // set the user lang in local storage
      if (localStorage.getItem('locale') === null) {
        localStorage.setItem('locale', appConfig.defaultLanguage);
      }

      return state.withMutations((st) => {
        st.set('user', user);
        st.delete('loginError');
        st.set('isLoading', false);
      });
    case userActions.LOGIN_FAILED:
      console.log('ERROR', action.payload);
      return state.withMutations((st) => {
        st.set('loginError', action.payload);
        st.set('isLoading', false);
      });
    case userActions.LOGOUT:
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('email');
      return state.withMutations((st) => {
        st.delete('user');
        st.delete('walletStatus');
        st.delete('status');
      });
  }

  switch (action.type) {
    case userActions.UPDATE_USER:
      // TEMPORARY FIX
      if (!action.payload.currentFinancialEntity) {
        action.payload.currentFinancialEntity =
          action.payload.financialEntities[0];
      }
      return state.set('user', fromJS(action.payload));
    case userActions.CHANGE_LOCALE:
      localStorage.setItem('locale', action.payload);
      return state.set('locale', action.payload);
    case userActions.CHANGE_PASSWORD_REQUEST:
    case userActions.CHANGE_PASSWORD_SUCCESS:
    case userActions.CHANGE_PASSWORD_FAILURE:
      return state.set('status', action.payload);
    case userActions.RESET_STATUS:
      return state.set('status', null);
  }

  switch (action.type) {
    case userActions.GET_BALANCE_REQ:
      return state.set('walletStatus', fromJS({ loading: true }));
    case userActions.GET_BALANCE_SUCCESS:
      return state.set(
        'walletStatus',
        fromJS({
          ...action.payload,
          loading: false,
          balance: parseFloat(action.payload.balance),
        })
      );
    case userActions.GET_BALANCE_FAILED:
      console.error('Error getting user balance', action.payload);
      return state.set('walletStatus', fromJS({ loading: false }));
  }

  switch (action.type) {
    case userActions.CLEAR_LOGIN_ERROR:
      return state.set('loginError', action.payload);
  }
  return state;
};
