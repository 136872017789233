import axios from 'axios';
import { appConfig, endPoints } from '../config/config';
import interceptors from './interceptors';
import Auth0Helper from '../components/Utils/Auth0Helper';
import moment from 'moment/moment';

const instance = axios.create({
  baseURL: endPoints.investorDashboard,
  timeout: 10000,
});

instance.interceptors.request.use(interceptors.authInterceptor);
instance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

const newPlatformInstance = axios.create({
  baseURL: endPoints.investor,
  timeout: 10000,
});

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

newPlatformInstance.interceptors.request.use(interceptors.authInterceptor);
newPlatformInstance.interceptors.response.use(
  interceptors.responseInterceptor,
  interceptors.responseErrorInterceptor
);

// export const getDashboardData = (lang) =>
//     instance.get("", {timeout: 40000, params: {language: lang}}).then(res => {
//         console.log(res);
//         return res.data;
//     });

export const getDashboardTransactions = (language) => {
  if (appConfig.isNewPlatform) {
    if (Auth0Helper.isAuthenticated()) {
      return newPlatformInstance
        .post('/getTransactions', {}, axiosConfig)
        .then((res) => {
          return res.data;
        });
    }
    return Promise.reject();
  }

  return instance
    .get(`/transactions`, { timeout: 60000, params: { language: language } })
    .then((res) => {
      return res.data;
    });
};

export const getDashboardAssets = (language) => {
  if (appConfig.isNewPlatform) {
    if (Auth0Helper.isAuthenticated()) {
      return newPlatformInstance
        .post('/getHoldings', {}, axiosConfig)
        .then((res) => {
          const hh = res.data.holdings.map((item) => {
            return {
              ...item,
              netProfit: item.currentValue - item.startValue,
              expectedProfit: item.endValue - item.startValue,
              daysToMaturity: moment
                .duration(moment.utc(item.redemptionDate).diff(moment.utc()))
                .asDays(),
            };
          });
          return { assets: hh };
        });
    }
    return Promise.reject();
  }
  return instance
    .get(`/assets`, { timeout: 60000, params: { language: language } })
    .then((res) => {
      return res.data;
    });
};

export const getDashboardTotals = (language) => {
  if (appConfig.isNewPlatform) {
    if (Auth0Helper.isAuthenticated()) {
      return newPlatformInstance.post('/getDashboardTotals').then((res) => {
        return { ...res.data };
      });
    }
    return Promise.reject();
  }
  return instance
    .get(`/totals`, { timeout: 60000, params: { language: language } })
    .then((res) => {
      return res.data;
    });
};
