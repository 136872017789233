import './Header.scss';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import { navigationURLs } from '../../config/config.js';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import InvestorTabs from './InvestorTabs';
import { connect } from 'react-redux';
import { logOut } from '../../redux/actions/user.actions';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  logOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.logOut();
  }

  renderTabs(tabs) {
    return tabs.map((tab, index) => (
      <div className='menu-item' key={index}>
        <NavLink
          disabled={tab.isDisable}
          to={tab.linkTo}
          className={`page`}
          activeClassName='active'
          onClick={this.props.onClose}
        >
          <span className=''>{tab.displayLabel}</span>
        </NavLink>
      </div>
    ));
  }

  tabs() {
    return <InvestorTabs user={this.props.user} renderTabs={this.renderTabs} />;
  }

  drawerClick(e) {
    e.stopPropagation();
  }

  render() {
    const { investorSettings, investorContactUs } = navigationURLs;
    return (
      <div
        className={`mobile-menu-wrapper ${this.props.open ? 'open' : ''}`}
        onClick={this.props.onClose}
      >
        <div className='mobile-menu' onClick={this.drawerClick}>
          <div className='menu-header'>
            <div className='menu-close' onClick={this.props.onClose}></div>
          </div>
          <div className='menu-items'>
            {!!this.props.user && this.tabs()}
            {/*        <div className="menu-item">*/}
            {/*            <NavLink*/}
            {/*                to={investorSettings}*/}
            {/*                isActive={this.activate}*/}
            {/*                activeClassName="active"*/}
            {/*                className="page"*/}
            {/*                onClick={this.props.onClose}*/}
            {/*            >*/}
            {/*<span className="">*/}
            {/*  <FormattedMessage*/}
            {/*      id={"header.tab.dropdownUser.settings"}*/}
            {/*      defaultMessage={"Settings"}/>*/}
            {/*</span>*/}
            {/*            </NavLink>*/}
            {/*        </div>*/}
            <div className='menu-item'>
              <NavLink
                to={investorContactUs}
                isActive={this.activate}
                activeClassName='active'
                className='page'
                onClick={this.props.onClose}
              >
                <span className=''>
                  <FormattedMessage
                    id={'header.tab.dropdownUser.contactUs'}
                    defaultMessage={'Contact us'}
                  />
                </span>
              </NavLink>
            </div>
            <div className='menu-item'>
              <a
                href='/'
                onClick={this.logOut}
                className='header-icon header-icon-logout'
              >
                <span className=''>
                  <FormattedMessage
                    id={'header.tab.dropdownUser.logOut'}
                    defaultMessage={'Logout'}
                  />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.get('user') ? state.auth.get('user').toJS() : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(logOut());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
